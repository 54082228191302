import React from "react";
// import { Link } from "react-router-dom";
// import useOnScreen from "../../store/InViewHook";
import ServiceItem from "./ServiceItem";
import textsJson from "./texts.json";
import styles from "./Services.module.css";

const Services = () => {
  const serviceTxt = textsJson;

  return (
    <section id="leistungen" className={styles.services}>
      <div className={styles["services-title"]}>
        <h2>Unser Angebot an Sie</h2>
      </div>
      <div className={styles["services-items"]}>
        {serviceTxt.map((item) => {
          return (
            <ServiceItem
              key={item.id}
              icon={item.icon}
              title={item.title}
              description={item.description}
              annex={item.annex}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Services;
