import React from "react";

import styles from "./Home.module.css";
import background from "../../assets/home-image.jpg";

const Home = () => {
  return (
    <section id="home">
      <div className={styles.home}>
        <div className={styles.intro}>
          <h1>
            Willkommen bei{" "}
            <span className={styles.logo}>
              s<span className={styles.letter}>i</span>lengua
            </span>
          </h1>
          <p>
            Ihrem Partner in Sachen{" "}
            <span className={styles.bold}>Fachübersetzungen</span>
          </p>
          <div className={styles.wrapper}>
            <p className={styles.languages}>Polnisch</p>
            <p className={styles.languages}>&#60; &#62;</p>
            <p className={styles.languages}>Deutsch</p>
          </div>
        </div>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${background})` }}
        >
          {/* <img
            src={background}
            alt="office desk"
            class={styles.responsive}
          ></img> */}
        </div>
      </div>
    </section>
  );
};

export default Home;
