import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import About from "./about/About";
import Home from "./home/Home";
import Services from "./services/Services";
import ServicesB2C from "./pages/ServicesB2C";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import AGB from "./pages/AGB";
// import Values from "./about/Values";

const Main = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash]); // do this on route change

  return (
    <main>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/welcome" />
        </Route>
        <Route path="/welcome">
          <Home />
          <Services />
          <About />
        </Route>
        <Route path="/servicesB2C">
          <ServicesB2C />
        </Route>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/datenschutz">
          <Datenschutz />
        </Route>
        <Route path="/agb">
          <AGB />
        </Route>
      </Switch>
    </main>
  );
};

export default Main;
