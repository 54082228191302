import styles from "./Services.module.css";

const ServiceItem = (props) => {
  return (
    <div className={styles.item}>
      <div
        className={styles.icon}
        dangerouslySetInnerHTML={{ __html: props.icon }}
      ></div>
      <span className={styles["itm-title"]}>{props.title}</span>
      <span className={styles["itm-descr"]}>{props.description}</span>
      <span className={styles["itm-descr"]}>{props.annex}</span>
    </div>
  );
};

export default ServiceItem;
