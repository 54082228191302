import React, { useContext } from "react";
import { Link } from "react-router-dom";
import MobileView from "../store/mobileViewContext";
import styles from "./Navbar.module.css";

const Navbar = (props) => {
  const ctx = useContext(MobileView);

  const handleNav = () => {
    props.handleMobile(false);
  };

  return (
    <nav className={styles.navbar}>
      <Link to="/welcome" {...(ctx.isMobileView && { onClick: handleNav })}>
        Home
      </Link>
      <Link
        to="/welcome#leistungen"
        {...(ctx.isMobileView && { onClick: handleNav })}
      >
        Leistungen
      </Link>
      <Link
        to="/welcome#about"
        {...(ctx.isMobileView && { onClick: handleNav })}
      >
        Über uns
      </Link>
      <Link
        to="/welcome#kontakt"
        {...(ctx.isMobileView && { onClick: handleNav })}
      >
        Kontakt
      </Link>
    </nav>
  );
};

export default Navbar;
