import React from "react";
import styles from "./ServicesB2C.module.css";

const ServicesB2C = () => {
  return (
    <section className={styles.text}>
      <h2>Sie benötigen eine Übersetzung für folgende Unterlagen:</h2>
      <ul className={styles.list}>
        <li>Beispiel A</li>
        <li>Beispiel B</li>
        <li>Beispiel C</li>
      </ul>
      <p> Bitte treten sie mit mir in Kontakt</p>
    </section>
  );
};

export default ServicesB2C;
