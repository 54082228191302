import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer id="kontakt" className={styles.footer}>
      <div className={styles.title}>
        <h2>Kontakt</h2>
      </div>
      <div className={styles.address}>
        <h3>silengua Fachübersetzungen</h3>
        <h4>Daniela Gessler</h4>
        <p>Werraweg 7</p>
        <p>33689 Bielefeld</p>
        <p>Tel. +49 (0) 5205 9156809</p>
        <p>E-Mail: gessler@silengua.de</p>
      </div>
      <div className={styles.links}>
        <Link to="/impressum">Impressum</Link>
        <Link to="/datenschutz">Datenschutz</Link>
        <Link to="/agb">Allgemeine Auftragsbedingungen</Link>
      </div>
      <div className={styles.copyright}>
        <p> &#169; 2022 silengua Fachübersetzungen</p>
      </div>
    </footer>
  );
};

export default Footer;
