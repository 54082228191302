import styles from "./Legal.module.css";

const Datenschutz = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.legal}>
        <div className={styles.title}>
          <h2>Datenschutzerklärung</h2>
        </div>
        <div className={styles.content}>
          <h3>1. Datenschutz auf einen Blick</h3>
          <h4>Allgemeine Hinweise</h4>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <h4>Datenerfassung auf dieser Website</h4>
          <h5>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </h5>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </p>
          <h5>Wie erfassen wir Ihre Daten?</h5>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>
          <p>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
            Besuch der Website durch unsere IT- Systeme erfasst. Das sind vor
            allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.
          </p>
          <h5>Wofür nutzen wir Ihre Daten?</h5>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <h5>Welche Rechte haben Sie bezüglich Ihrer Daten?</h5>
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          <p>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
            sich jederzeit an uns wenden.
          </p>
          <h4>Analyse-Tools und Tools von Drittanbietern</h4>
          <p>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen.
          </p>
          <p>
            Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
            der folgenden Datenschutzerklärung.
          </p>
          <h3>2. Hosting</h3>
          <h4>IONOS</h4>
          <p>
            Wir hosten unsere Website bei IONOS SE. Anbieter ist die IONOS SE,
            Elgendorfer Str. 57, 56410 Montabaur (nachfolgend: IONOS). Wenn Sie
            unsere Website besuchen, erfasst IONOS verschiedene Logfiles
            inklusive Ihrer IP-Adressen. Details entnehmen Sie der
            Datenschutzerklärung von IONOS:{" "}
            <a
              href="https://www.ionos.de/terms-gtc/terms-privacy."
              target="_blank"
              rel="noreferrer"
            >
              https://www.ionos.de/terms-gtc/terms-privacy.
            </a>
          </p>
          <p>
            Die Verwendung von IONOS erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
            möglichst zuverlässigen Darstellung unserer Website. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar.
          </p>
          <h5>Auftragsverarbeitung</h5>
          <p>
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
            genannten Anbieter geschlossen. Hierbei handelt es sich um einen
            datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet,
            dass dieser die personenbezogenen Daten unserer Websitebesucher nur
            nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <h3>3. Allgemeine Hinweise und Pflichtinformationen</h3>
          <h4>Datenschutz</h4>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene
            personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
            mit denen Sie persönlich identifiziert werden können. Die
            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
            und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
            Zweck das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
          <h4>Hinweis zur verantwortlichen Stelle</h4>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <p>
            silengua Fachübersetzungen
            <br /> Daniela Gessler
            <br /> Werraweg 7<br /> 33689 Bielefeld
            <br />
            Deutschland
          </p>
          <p>
            Telefon: 0049 5205 9156809 <br /> E-Mail: gessler@silengua.de
          </p>
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z. B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <h4>Speicherdauer</h4>
          <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </p>
          <h4>
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
          </h4>
          <p>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den
            USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten.
            Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in
            diese Drittstaaten übertragen und dort verarbeitet werden. Wir
            weisen darauf hin, dass in diesen Ländern kein mit der EU
            vergleichbares Datenschutzniveau garantiert werden kann.
            Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </p>
          <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <h4>
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </h4>
          <p>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
            VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN 5 / 11 WIDERSPRUCH
            EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
            VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN
            SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
            PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR
            KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
            ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
            VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
            DSGVO).
          </p>
          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
            BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
            DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
            WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
            NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH
            ART. 21 ABS. 2 DSGVO).
          </p>
          <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </p>
          <h4>Recht auf Datenübertragbarkeit</h4>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
          <h4>SSL- bzw. TLS-Verschlüsselung</h4>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS- Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
          <h4>Auskunft, Löschung und Berichtigung</h4>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </p>
          <h4>Recht auf Einschränkung der Verarbeitung</h4>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
          </p>
          <p>
            &#8226; Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
            um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen.
            <br />
            &#8226; Wenn die Verarbeitung Ihrer personenbezogenen Daten
            unrechtmäßig geschah/geschieht, können Sie statt der Löschung die
            Einschränkung der Datenverarbeitung verlangen.
            <br />
            &#8226; Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen,
            Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
            Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen.
            <br />
            &#8226; Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
            eingelegt haben, muss eine Abwägung zwischen Ihren und unseren
            Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
            Interessen überwiegen, haben Sie das Recht, die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </p>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <h4>Widerspruch gegen Werbe-E-Mails</h4>
          <p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </p>
          <h3 id="cookies">4. Datenerfassung auf dieser Website</h3>
          <h4>Cookies</h4>
          <p>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert, bis Sie diese selbst löschen oder eine automatische
            Löschung durch Ihren Webbrowser erfolgt.
          </p>
          <p>
            Teilweise können auch Cookies von Drittunternehmen auf Ihrem
            Endgerät gespeichert werden, wenn Sie unsere Seite betreten
            (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
            bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
            Abwicklung von Zahlungsdienstleistungen).
          </p>
          <p>
            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
            technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
            funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige
            von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
            auszuwerten oder Werbung anzuzeigen.
          </p>
          <p>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
            bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies,
            z. B. für die Warenkorbfunktion) oder zur Optimierung der Website
            (z. B. Cookies zur Messung des Webpublikums) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert,
            sofern keine andere Rechtsgrundlage angegeben wird. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
            Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der
            betreffenden Cookies ausschließlich auf Grundlage dieser
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
            jederzeit widerrufbar.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
          <p>
            Soweit Cookies von Drittunternehmen oder zu Analysezwecken
            eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
            Datenschutzerklärung gesondert informieren und ggf. eine
            Einwilligung abfragen.
          </p>
          <h4>Anfrage per E-Mail, Telefon oder Telefax</h4>
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraushervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mitder Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher
            Maßnahmenerforderlich ist. In allen übrigen Fällen beruht die
            Verarbeitung auf unserem berechtigten Interesse an dereffektiven
            Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
            DSGVO) oder auf IhrerEinwilligung (Art. 6 Abs. 1 lit. a DSGVO)
            sofern diese abgefragt wurde.
          </p>
          <p>
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten
            verbleiben bei uns, bis Sie uns zur Löschungauffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt(z. B. nach abgeschlossener Bearbeitung
            Ihres Anliegens). Zwingende gesetzliche Bestimmungen –insbesondere
            gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </p>
          <h4>Kommunikation via WhatsApp</h4>
          <p>
            Für die Kommunikation mit unseren Kunden und sonstigen Dritten
            nutzen wir unter anderem den Instant-Messaging-Dienst WhatsApp.
            Anbieter ist die WhatsApp Ireland Limited, 4 Grand Canal Square,
            GrandCanal Harbour, Dublin 2, Irland.
          </p>
          <p>
            Die Kommunikation erfolgt über eine Ende-zu-Ende-Verschlüsselung
            (Peer-to-Peer), die verhindert, dass WhatsApp oder sonstige Dritte
            Zugriff auf die Kommunikationsinhalte erlangen können. WhatsApp
            erhält jedoch Zugriff auf Metadaten, die im Zuge des
            Kommunikationsvorgangs entstehen (z. B. Absender,Empfänger und
            Zeitpunkt). Wir weisen ferner darauf hin, dass WhatsApp nach eigener
            Aussage, personenbezogene Daten seiner Nutzer mit seiner in den USA
            ansässigen Konzernmutter Facebook teilt. Weitere Details zur
            Datenverarbeitung finden Sie in der Datenschutzrichtlinie von
            WhatsApp unter:&nbsp;
            <a href="https://www.whatsapp.com/legal/#privacy-policy">
              https://www.whatsapp.com/legal/#privacy-policy
            </a>
            .
            <p>
              Der Einsatz von WhatsApp erfolgt auf Grundlage unseres
              berechtigten Interesses an einer möglichst schnellen und
              effektiven Kommunikation mit Kunden, Interessenten und sonstigen
              Geschäfts- und Vertragspartnern (Art. 6 Abs. 1 lit. f DSGVO).
              Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
              die Datenverarbeitung ausschließlich auf Grundlage der
              Einwilligung; diese ist jederzeit mit Wirkungfür die Zukunft
              widerrufbar.
            </p>
            <p>
              Die zwischen und auf WhatsApp ausgetauschten Kommunikationsinhalte
              verbleiben bei uns, bis Sie uns zurLöschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
              Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>
          </p>
          <h3>5. Soziale Medien</h3>
          <h4>eRecht24 Safe Sharing Tool</h4>
          <p>
            Die Inhalte auf dieser Website können datenschutzkonform in sozialen
            Netzwerken wie Facebook, Twitter & Co. geteilt werden. Diese Seite
            nutzt dafür das{" "}
            <a href="https://www.e-recht24.de/erecht24-safe-sharing.html">
              eRecht24 Safe Sharing Tool
            </a>
            . Dieses Tool stellt den direkten Kontakt zwischen den Netzwerken
            und Nutzernerst dann her, wenn der Nutzer aktiv auf einen dieser
            Button klickt. Der Klick auf den Button stellt eine Einwilligung im
            Sinne des Art. 6 Abs. 1 lit. a DSGVO dar. Diese Einwilligung kann
            jederzeit mit Wirkung fürdie Zukunft widerrufen werden.
          </p>
          <p>
            Eine automatische Übertragung von Nutzerdaten an die Betreiber
            dieser Plattformen erfolgt durch diesesTool nicht. Ist der Nutzer
            bei einem der sozialen Netzwerke angemeldet, erscheint bei der
            Nutzung der Social-Buttons von Facebook, Twitter & Co. ein
            Informations-Fenster, in dem der Nutzer den Text vor dem Absenden
            bestätigen kann.
          </p>
          <p>
            Unsere Nutzer können die Inhalte dieser Seite datenschutzkonform in
            sozialen Netzwerken teilen, ohne dass komplette Surf-Profile durch
            die Betreiber der Netzwerke erstellt werden.
          </p>
          <h3>6. Analyse-Tools und Werbung</h3>
          <h4>IONOS WebAnalytics</h4>
          <p>
            Diese Website nutzt die Analysedienste von IONOS WebAnalytics (im
            Folgenden: IONOS). Anbieter ist die1&1 IONOS SE, Elgendorfer Straße
            57, D – 56410 Montabaur. Im Rahmen der Analysen mit IONOS können u.
            a. Besucherzahlen und –verhalten (z. B. Anzahl der Seitenaufrufe,
            Dauer eines Webseitenbesuchs,Absprungraten), Besucherquellen (d. h.,
            von welcher Seite der Besucher kommt), Besucherstandorte sowie
            technische Daten (Browser- und Betriebssystemversionen) analysiert
            werden. Zu diesem Zweck speichert IONOS insbesondere folgende Daten:
          </p>
          <ul>
            <li>Referrer (zuvor besuchte Webseite)</li>
            <li>angeforderte Webseite oder Datei</li>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>verwendeter Gerätetyp</li>
            <li>Uhrzeit des Zugriffs</li>
            <li>
              IP-Adresse in anonymisierter Form (wird nur zur Feststellung des
              Orts des Zugriffs verwendet)
            </li>
          </ul>
          <p>
            Die Datenerfassung erfolgt laut IONOS vollständig anonymisiert,
            sodass sie nicht zu einzelnen Personen zurückverfolgt werden kann.
            Cookies werden von IONOS WebAnalytics nicht gespeichert.
          </p>
          <p>
            Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art.
            6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an der statistischen Analyse des Nutzerverhaltens, um
            sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern
            eine entsprechende Einwilligungabgefragt wurde, erfolgt die
            Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
            DSGVO; die Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Weitere Informationen zur Datenerfassung und Verarbeitung durch
            IONOS WebAnalytics entnehmen Sieder Datenschutzerklaerung von IONOS
            unter folgendem Link:
          </p>
          <p>
            <a href="https://www.ionos.de/terms-gtc/index.php?id=6">
              https://www.ionos.de/terms-gtc/index.php?id=6
            </a>
          </p>
          <h4>Auftragsverarbeitung</h4>
          <p>
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
            genannten Anbieter geschlossen. Hierbei handelt es sich um einen
            datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet,
            dass dieser die personenbezogenen Daten unserer Websitebesucher nur
            nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Datenschutz;
