import React, { useState, Fragment } from "react";
import Header from "./header/Header";
import Main from "./main/Main";
import Footer from "./footer/Footer";
import NavMobile from "./header/NavMobile";
// import GDPRCookiesPolicy from "./store/Gdpr";
import MobileView from "./store/mobileViewContext";

import styles from "./App.module.css";

function App() {
  const [mobileNav, setMobileNav] = useState(false);
  // const [banner, setBanner] = useState(true);

  const renderMobileNav = (bool) => {
    setMobileNav(bool);
  };

  // const handleBanner = (bool) => {
  //   setBanner(bool);
  // };

  return (
    <Fragment>
      <MobileView.Provider value={{ isMobileView: mobileNav }}>
        {mobileNav && <NavMobile renderMobileNav={renderMobileNav} />}
        {!mobileNav && (
          <div className={styles.app}>
            <Header renderMobileNav={renderMobileNav} />
            <Main />
            <Footer />
            {/* {banner && <GDPRCookiesPolicy bannerState={handleBanner} />} */}
          </div>
        )}
      </MobileView.Provider>
    </Fragment>
  );
}

export default App;
