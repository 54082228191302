import React from "react";
import Values from "./Values";
import styles from "./About.module.css";
import image from "../../assets/Portfolio/profile.jpg";

const About = () => {
  return (
    <div className={styles.about} id="about">
      <div className={styles["about-header"]}>
        <h2>Ihre Ansprechpartnerin</h2>
      </div>
      <div className={styles["about-main"]}>
        <div className={styles["about-title"]}>
          <h2>Daniela Gessler</h2>
          <h3>
            Dipl. Übersetzerin und vom OLG Hamm ermächtigte Urkundenübersetzerin
          </h3>
        </div>
        <div
          className={styles["about-image"]}
          // style={{ backgroundImage: `url(${image})` }}
        >
          <img src={image} alt="Daniela Gessler"></img>
        </div>
        <div className={styles["about-description"]}>
          <p>
            Ich bin der Kopf hinter silengua und Ihre Ansprechpartnerin.
            Zuständig für Übersetzungen Polnisch&#60;&#62;Deutsch und die
            Koordination von Übersetzungsprojekten.
            <br />
            Mit fundierter Ausbildung und ständiger Weiterbildung in den
            Fachgebieten stehe ich meinen Kunden seit fast 20 Jahren zur Seite
            in allen Fragen der Sprachmittlung.
            <br />
            Die Zusammenarbeit mit fachkundigen Kolleginnen und Kollegen
            ermöglicht mir, auf individuelle Bedürfnisse der Kunden passgenau
            und termintreu einzugehen.
          </p>
        </div>
      </div>
      <Values />
    </div>
  );
};

export default About;
