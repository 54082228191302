import styles from "./Legal.module.css";

const AGB = () => {
  return (
    <div className={styles.legal}>
      <div className={styles.title}>
        <h2>Allgemeine Auftragsbedingungen</h2>
      </div>
      <div className={styles.content}>
        <h3>1. Geltungsbereich</h3>
        <p>
          (1) Diese Auftragsbedingungen gelten für Rechtsgeschäfte und
          rechtsgeschäftliche Handlungen mit Daniela Gessler, Werraweg 7, 33689
          Bielefeld (im Folgenden „Übersetzerin“ genannt), soweit nicht etwas
          anderes ausdrücklich vereinbart oder gesetzlich unabdingbar
          vorgeschrieben ist.
        </p>
        <p>
          (2) Allgemeine Geschäftsbedingungen des Auftraggebers sind für die
          Übersetzerin nur verbindlich, wenn sie sie ausdrücklich schriftlich
          anerkannt hat.
        </p>
        <h3>2. Umfang der Übersetzungsleistung</h3>
        <p>
          Die Übersetzung wird nach den Grundsätzen ordnungsgemäßer
          Berufsausübung sorgfältig und nach bestem Wissen angefertigt. Der
          Auftraggeber erhält die vertraglich vereinbarte Ausfertigung der
          Übersetzung wie vor Vertragsabschluss abgesprochen.
        </p>
        <h3>3. Mitwirkungs- und Aufklärungspflicht des Auftraggebers</h3>
        <p>
          Der Auftraggeber hat die Übersetzerin rechtzeitig und unaufgefordert
          über gewünschte Ausführungsformen der Übersetzung zu unterrichten.
          Solche sind z. B. Verwendungszweck, Zielland, Lieferung auf
          Datenträgern, Anzahl der Ausfertigungen, Druckreife und äußere Form
          der Übersetzung, etc. Ist die Übersetzung für den Druck bestimmt,
          überlässt der Auftraggeber der Übersetzerin rechtzeitig vor
          Drucklegung einen Korrekturabzug, so dass die Übersetzerin eventuelle
          Fehler beseitigen kann. Informationen und Unterlagen, die zur
          Erstellung der Übersetzung notwendig sind, stellt der Auftraggeber der
          Übersetzerin bei Erteilung des Auftrags zur Verfügung. Solche
          Informationen sind u. a. besondere Terminologie des Bestellers,
          Abbildungen, Zeichnungen, Tabellen, Abkürzungen und interne Begriffe.
          <br />
          Fehler und Verzögerungen, die sich aus der mangelnden oder verzögerten
          Lieferung von Informationsmaterial und Anweisungen ergeben, gehen
          nicht zu Lasten der Übersetzerin. Der Auftraggeber übernimmt die
          Haftung für die Rechte an einem Text und stellt sicher, dass eine
          Übersetzung angefertigt werden darf. Von entsprechenden Ansprüchen
          Dritter stellt er die Übersetzerin frei.
        </p>
        <h3>4. Rechte des Auftraggebers bei Mängeln</h3>
        <p>
          Die Übersetzerin behält sich das Recht auf Mängelbeseitigung vor. Der
          Auftraggeber hat zunächst nur Anspruch auf Beseitigung von möglichen
          in der Übersetzung enthaltenen Mängeln. Der Anspruch auf
          Mängelbeseitigung muss vom Auftraggeber schriftlich unter genauer
          Angabe des Mangels geltend gemacht werden.
        </p>
        <h3>5. Haftung</h3>
        <p>
          Die Übersetzerin haftet nur bei grober Fahrlässigkeit und Vorsatz.
          Nicht als grobe Fahrlässigkeit einzustufen sind Schäden, welche durch
          Computerausfälle und Übertragungsstörungen bei E-Mail- Versand oder
          durch Computerviren verursacht worden sind. Die Übersetzerin trifft
          durch geeignete Anti Virus-Software hiergegen Vorkehrungen. <br />
          Die Haftung bei leichter Fahrlässigkeit gilt ausschließlich im Falle
          der Verletzung von Hauptpflichten. Hierbei wird die Ersatzpflicht der
          Übersetzerin auf 5.000,00 Euro begrenzt, wenn nicht gesondert und
          schriftlich ein höherer Betrag vereinbart wird. Ansprüche des
          Auftraggebers gegen der Übersetzerin wegen Mängeln an der Übersetzung
          (§ 634a BGB) verjähren, sofern nicht Arglist vorliegt, in einem Jahr
          seit der Abnahme der Übersetzung. <br />
          Die Haftung für Mangelfolgeschäden ist entgegen § 634a BGB auf die
          gesetzliche Verjährungsfrist beschränkt. Hiervon bleibt § 202 Abs. 1
          BGB unberührt.
        </p>
        <h3>6. Verschwiegenheitserklärung</h3>
        <p>
          Die Übersetzerin verpflichtet sich, Stillschweigen über alle Tatsachen
          und Handlungen zu bewahren, die ihr im Zusammenhang mit ihrer
          Tätigkeit zu Kenntnis gelangen.
        </p>
        <h3>7. Erfüllungsgehilfen</h3>
        <p>
          Die Übersetzerin ist jederzeit ohne Ankündigung berechtigt,
          Mitarbeiter oder fachkundige Dritte ihrer Wahl als Erfüllungsgehilfen
          heranzuziehen. Bei Heranziehung von fachkundigen Dritten hat die
          Übersetzerin dafür zu sorgen, dass sich diese zur Verschwiegenheit
          entsprechend Nr. 6. verpflichten.
        </p>
        <h3>8. Vergütung</h3>
        <p>
          Die Rechnungen der Übersetzerin sind ohne Abzug innerhalb von 30 Tagen
          ab Tag nach Zugang der Rechnung fällig und zahlbar. Ist die Höhe des
          Honorars nicht vereinbart, so ist eine nach Art und Schwierigkeit
          angemessene und übliche Vergütung geschuldet. Diese unterschreitet die
          jeweils geltenden Sätze des Justizvergütungs- und
          -entschädigungsgesetzes (JVEG) nicht. Alle Preise verstehen sich netto
          und zuzüglich der gesetzlichen Umsatzsteuer.
        </p>
        <h3>9. Eigentumsvorbehalt und Urheberrecht</h3>
        <p>
          Die Übersetzung bleibt bis zur vollständigen Bezahlung Eigentum der
          Übersetzerin. Alle Rechte Verbleiben bis zur vollständigen Bezahlung
          bei der Übersetzerin.
        </p>
      </div>
    </div>
  );
};

export default AGB;
