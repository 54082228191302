import React, { useEffect } from "react";
import Navbar from "./Navbar";

import styles from "./NavMobile.module.css";

const NavMobile = (props) => {
  const handleCloseBtn = () => {
    props.renderMobileNav(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        props.renderMobileNav(false);
      }
    };

    window.addEventListener("resize", handleResize);
  }, [props]);

  return (
    <section className={styles["mobile-nav"]}>
      <button className={styles["close-btn"]} onClick={handleCloseBtn}>
        X
      </button>
      <Navbar handleMobile={handleCloseBtn} />
    </section>
  );
};

export default NavMobile;
