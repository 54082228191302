import React from "react";
import styles from "./Values.module.css";

const Values = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Unser Versprechen</div>
      <div className={styles.values}>
        <div className={styles.value1}>
          <div className={styles.icon}>&#10122;</div>
          <div>Langjährige Erfahrung</div>
        </div>
        <div className={styles.value2}>
          <div className={styles.icon}>&#10123;</div>
          <div>Fachwissen</div>
        </div>
        <div className={styles.value3}>
          <div className={styles.icon}>&#10124;</div>
          <div>Ständige Weiterbildung</div>
        </div>
        <div className={styles.value4}>
          <div className={styles.icon}>&#10125;</div>
          <div>Qualitätsanspruch</div>
        </div>
      </div>
    </div>
  );
};

export default Values;

// &#10072; &#10072; /// &#8213;
