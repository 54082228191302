import React from "react";
import Navbar from "./Navbar";
import logo from "../assets/Logo/Silengua.png";
import { Link } from "react-router-dom";
import { ReactComponent as Hamburger } from "../assets/Icons/Hamburger.svg";
// import { ReactComponent as Logo } from "../assets/Logo/Silengua.svg";

import styles from "./Header.module.css";

const Header = (props) => {
  const handleNavClick = () => {
    props.renderMobileNav(true);
  };
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to="/welcome">
          <img src={logo} alt="logo"></img>
        </Link>
      </div>
      <div className={styles.hamburger}>
        <Hamburger onClick={handleNavClick} />
      </div>
      <div className={styles.nav}>
        <Navbar />
      </div>
    </header>
  );
};

export default Header;
